@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .cursor-drag {
    cursor: url('~assets/drag.svg'), auto;
  }
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

:root {
  --primary-100: #f2ebf9;
  --primary-200: #ddccef;
  /* default */
  --primary-300: #5301b1;
  /* hover */
  --primary-400: #4b01a0;
  --primary-500: #3e0185;
  --primary-600: #32016a;

  --background-100: #fff;
  --background-200: #faf9fb;
  --background-300: #f5f4f6;
  --background-400: #edebee;

  --stroke-100: #e5e4e7;
  --stroke-200: #cbc9cf;

  --text-100: #fff;
  --text-200: #99949e;
  --text-300: #66616b;
  --text-400: #4d4950;
  --text-500: #333036;
  --text-600: #1a181b;

  --link-100: #ebf5ff;
  --link-200: #cce4ff;
  --link-300: #007aff;
  --link-400: #0062cc;
  --link-500: #0062cc;
  --link-600: #004999;

  --success-100: #ecfaf0;
  --success-200: #cff2d8;
  --success-300: #10bc3b;
  --success-400: #0eaa35;
  --success-500: #0d962f;
  --success-600: #0a7123;

  --error-100: #ffefef;
  --error-200: #ffd6d6;
  --error-300: #ff3333;
  --error-400: #e62e2e;
  --error-500: #cc2929;
  --error-600: #991f1f;

  --warning-100: #fff7eb;
  --warning-200: #ffeacc;
  --warning-300: #ff9500;
  --warning-400: #e68600;
  --warning-500: #cc7700;
  --warning-600: #995900;

  --components-tooltip-background: #141216;
  --components-tooltip-bodyText: #ccc9cf;

  --components-avatar-user: #bfbcc2;

  --components-callState-background: #fff;
  --components-callState-recordBar: #d9d5dd;

  --components-tabs-background: #f5f4f6;
  --components-tabs-active: #fff;

  --components-leftBar-background: #000;

  --components-modal-background: #fff;

  --components-dropdown-background: #fff;
  --components-dropdown-hover: #faf9fb;
  --components-dropdown-active: #f5f4f6;

  --tags-blue: #6d8def;
  --tags-orchid: #aa6def;
  --tags-magenta: #ef6dc2;
  --tags-lilac: #e247f0;
  --tags-vermillion: #ef6d6d;
  --tags-grape: #7a6def;
  --tags-pacific: #6dc2ef;
  --tags-lime: #b0ef6d;
  --tags-pear: #6defa5;
  --tags-cyan: #6defe4;
  --tags-mustard: #f7e56e;
  --tags-amber: #efb36d;
}

.dark:root {
  --primary-100: #1d003d;
  --primary-200: #300166;
  --primary-300: #5f01cb;
  --primary-400: #7727d3;
  --primary-500: #8f4dda;
  --primary-600: #bf99ea;

  --background-100: #141216;
  --background-200: #19171c;
  --background-300: #1f1c22;
  --background-400: #242027;

  --stroke-100: #26232a;
  --stroke-200: #333036;

  --text-100: #ffffff;
  --text-200: #66616b;
  --text-300: #b2afb6;
  --text-400: #ccc9cf;
  --text-500: #e5e4e7;
  --text-600: #f3f2f3;

  --link-100: #08294d;
  --link-200: #0d4480;
  --link-300: #1987ff;
  --link-400: #0062cc;
  --link-500: #5eabff;
  --link-600: #a3cfff;

  --success-100: #054014;
  --success-200: #096a21;
  --success-300: #12d342;
  --success-400: #35da5e;
  --success-500: #59e07a;
  --success-600: #a0edb3;

  --error-100: #4d1717;
  --error-200: #802626;
  --error-300: #ff4c4c;
  --error-400: #ff6767;
  --error-500: #ff8181;
  --error-600: #ffb7b7;

  --warning-100: #4d3008;
  --warning-200: #80500d;
  --warning-300: #ffa019;
  --warning-400: #ffae3b;
  --warning-500: #ffbc5e;
  --warning-600: #ffd9a3;

  --components-tooltip-background: #232026;
  --components-tooltip-bodyText: #ccc9cf;

  --components-avatar-user: #7a7580;

  --components-callState-background: #2e2932;
  --components-callState-recordBar: #141316;

  --components-tabs-background: #0a090b;
  --components-tabs-active: #19171c;

  --components-leftBar-background: #000;

  --components-modal-background: #19171c;

  --components-dropdown-background: #1f1c22;
  --components-dropdown-hover: #242027;
  --components-dropdown-active: #29252d;
}
